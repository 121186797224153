import React, { useContext, useMemo, useState } from "react";
import OrchestratorService from "../../services/OrchestratorService";
import { TextCase } from "../MessageCases";
import { ListModal } from "..";
import { CONTENTTYPE } from "src/contexts/store/appReducer";

import {
  MessageContentType,
  PayloadProps,
  PayloadReceivedProps,
  PayloadReceivedTypeConst,
} from "./types";

import { FirebaseNotificationPayload } from "./FirebaseNotificationPayload";
import { RequestContext } from "src/contexts/requestContext";
import { IFramePayload } from "./IframePayload";
import { usePostMessage } from "src/hook/usePostMessage";

export function Payload({
  msg,
  sendMessage,
  sendRegisterDevice,
  isLastMessage,
}: PayloadProps) {
  function displayMessage(evt) {
    if (evt.data === "close-pwa") {
      setShow(false);
    }
  }

  usePostMessage({ displayMessage });

  const orchestratorService = OrchestratorService.Instance;
  const payload: PayloadReceivedProps = useMemo(() => {
    return typeof msg.text === "string" ? JSON.parse(msg.text) : msg.text;
  }, [msg]);

  const [show, setShow] = useState(isLastMessage);
  const requestContext = useContext(RequestContext);

  const fetchNotifyWebhook = async (ids = []) => {
    try {
      const data = JSON.stringify({ type: "cards", payload: { items: ids } })
        .replace("{{", "{ {")
        .replace("}}", "} }");
      const message = "##NOSHOW##" + data;

      requestContext?.setIsFetch(true);

      let userId = payload.userId ?? localStorage.getItem("userId") ?? "";
      localStorage.setItem("userId", userId);

      if (!!payload.onClose) {
        await orchestratorService.sendNotifyWebhook({
          id: userId,
          event: payload.onClose,
          eventParameters: {
            msg: "payload",
            payload: data,
          },
        });

        requestContext?.setIsFetch(false);
      } else {
        sendMessage(message, CONTENTTYPE.TEXT as MessageContentType);
      }

      setShow(false);
    } catch (err) {
      requestContext?.setIsFetch(false);
      setShow(false);
    }
  };

  const onChoice = (selectedItem) => {
    const ids = selectedItem.map((item) => item.id);
    fetchNotifyWebhook(ids ?? []);
  };

  if (payload.type === PayloadReceivedTypeConst.CARDS && show) {
    return (
      <ListModal
        msg={msg}
        suggestions={[]}
        onMessage={sendMessage}
        closeModal={() => {
          fetchNotifyWebhook([]);
        }}
        onChoice={onChoice}
        isFetch={requestContext?.isFetch}
      />
    );
  }

  if (payload.type === PayloadReceivedTypeConst.PUSH_NOTIFICATION && show) {
    return <FirebaseNotificationPayload {...payload} setShow={setShow} />;
  }

  if (payload.type === PayloadReceivedTypeConst.IFRAME && show) {
    return <IFramePayload {...payload} setShow={setShow} />;
  }

  return <TextCase msg={msg} type="cards" />;
}
