import React from "react";
import { MESSAGETYPE, STATUS } from "../../../contexts/store/appReducer";

export function TextComponent({ text, type, timestamp, status }) {
  text = !!text && text.type === "cards" ? JSON.stringify(text) : text;

  return (
    <>
      <p
        id={`message-${timestamp}`}
        className={`message-text ${
          type === MESSAGETYPE.OUTGOING ? "message-outgoing" : ""
        }`}
      >
        {text}
      </p>
      <p className="message-info">
        <span className="message-time">
          {new Date(timestamp).getHours().toString().padStart(2, "0") +
            ":" +
            new Date(timestamp).getMinutes().toString().padStart(2, "0")}
        </span>
        <span
          className={(() => {
            switch (status) {
              case STATUS.SENT:
                return "message-status sent";
              case STATUS.RECEIVED:
                return "message-status read";
              case STATUS.ERROR:
              case STATUS.SENDING:
                return "message-status sending";
              default:
                return "";
            }
          })()}
        ></span>
      </p>
    </>
  );
}
