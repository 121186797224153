import { useEffect, useState } from "react";
import { useSpeechRecognition } from "react-speech-recognition";
// import emptyAudio from "../../assets/empty.wav";

export function useAudioRecorderSpeechText() {
  const speechRecognition = useSpeechRecognition();
  const [isAfterOnStop, setIsAfterOnStop] = useState(false);
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (!speechRecognition.listening && isAfterOnStop) {
      if (!!speechRecognition.finalTranscript) {
        window.sendTextMessage(
          speechRecognition.finalTranscript,
          setIsAfterOnStop(false)
        );
      } else {
        // const audio = new Blob([emptyAudio], {
        //   type: "audio/ogg; codecs=opus",
        // });
        // window.asyncSendAudio(audio, setIsAfterOnStop(false));

        window.openToast(
          "Desculpe, não consegui compreender o seu áudio. Pode gravar novamente?",
          setIsAfterOnStop(false)
        );
      }
    }
  }, [
    isAfterOnStop,
    speechRecognition.finalTranscript,
    speechRecognition.listening,
  ]);

  return {
    isAfterOnStop,
    isRecording: speechRecognition.listening,
    result,
    setIsAfterOnStop,
    setResult,
    resetTranscript: speechRecognition.resetTranscript,
  };
}
