import React from "react";
import Lightbox from "react-image-lightbox";
import "./Image.scss";
import { STATUS } from "../../../contexts/store/appReducer";

export default function Image(props) {
  const { msg, scrollDown, showImage, setShowImage } = props;

  return (
    <>
      <div className="image-container">
        {msg.status === STATUS.SENDING ? (
          <p className="message-text">{msg.text}</p>
        ) : (
          <p className="message-image">
            <img
              onLoad={scrollDown}
              alt=""
              onClick={(e) => setShowImage(e.target.src)}
              src={msg.text}
            />
          </p>
        )}

        <p className="message-info">
          <span className="message-time">
            {new Date(msg.timestamp).getHours().toString().padStart(2, "0") +
              ":" +
              new Date(msg.timestamp).getMinutes().toString().padStart(2, "0")}
          </span>
          <span
            className={(() => {
              switch (msg.status) {
                case STATUS.SENT:
                  return "message-status sent";
                case STATUS.RECEIVED:
                  return "message-status read";
                case STATUS.ERROR:
                case STATUS.SENDING:
                  return "message-status sending";
                default:
                  return "";
              }
            })()}
          ></span>
        </p>
      </div>
      {showImage && (
        <Lightbox
          mainSrc={showImage}
          onCloseRequest={() => setShowImage(null)}
          toolbarButtons={[
            <a
              href={showImage}
              rel="noopener noreferrer"
              target="_blank"
              download
            >
              <button className="ril-download ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__downloadButton"></button>
            </a>,
          ]}
        />
      )}
    </>
  );
}
