import React from "react";
import { isDarkTheme } from "src/utils/changeTheme";
import { ListModalLoading } from "../ListModalLoading";

import "./ListModalConfirm.scss";

export default function ListModalConfirm(props) {
  const { totalItems, onConfirm, onClose, isFetch } = props;

  return (
    <div className={"list-modal-confirm-container"}>
      <div
        className={
          "list-modal-confirm-content loading " +
          (isDarkTheme() ? "darkTheme" : "")
        }
      >
        {isFetch ? (
          <>
            <h2 className="list-modal-confirm-content__text">
              Processando solicitação, aguarde...
            </h2>
            <ListModalLoading />
          </>
        ) : (
          <div
            className={
              "list-modal-confirm-content " + (isDarkTheme() ? "darkTheme" : "")
            }
          >
            <h2 className="list-modal-confirm-content__title">
              {`Você selecionou ${totalItems} ${
                totalItems === 1 ? "item" : "itens"
              }`}
            </h2>
            <p className="list-modal-confirm-content__text">
              Quer continuar?
            </p>
            <div className="list-modal-confirm-content__footer">
              <button
                className="list-modal-confirm-content__footer-cancel"
                type="button"
                onClick={onClose}
                disabled={isFetch}
              >
                Cancelar
              </button>
              <button
                className="list-modal-confirm-content__footer-confirm"
                type="button"
                onClick={onConfirm}
                disabled={isFetch}
              >
                Confirmar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
