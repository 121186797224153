import React, { useEffect } from "react";
import "./Audio.scss";

export default function Audio(props) {
  const { msg } = props;

  useEffect(() => {
    if (msg.status === "RECEIVED") {
      window.InitAudioPlayers();
    }
  }, [msg]);

  return (
    <>
      <p className="message-text">
        <div
          id="audioplayer"
          className="n-audio-player"
          data-src={msg.text}
          data-size="small"
        ></div>
      </p>
    </>
  );
}
