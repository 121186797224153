import "./Document.scss";

import React from "react";
import { MdFileDownload, MdDescription } from "react-icons/md";
import { STATUS } from "../../../contexts/store/appReducer";

export default function Document(props) {
  const { msg } = props;

  const renderBodyFile = () => {
    return (
      <div className="container-file">
        <span>
          <MdDescription className="document-icons" />
          <p>Documento</p>
        </span>
        <a
          className="document-download"
          href={msg.text}
          download="file"
					rel='noopener noreferrer'
          target="_blank"
        >
          <MdFileDownload className="document-icons icon-download" />
        </a>
      </div>
    );
  };

  const renderBodySending = () => {
    return <p className="message-text">{msg.text}</p>;
  };

  const renderFooter = () => {
    const msgArray = msg.text.split(".");
    const format = msgArray[msgArray.length - 1];
    return (
      <div className="doc-footer">
        <p className="message-info message-info-left">{format.toUpperCase()}</p>
        <p className="message-info">
          <span className="message-time">
            {new Date(msg.timestamp).getHours().toString().padStart(2, "0") +
              ":" +
              new Date(msg.timestamp).getMinutes().toString().padStart(2, "0")}
          </span>
          <span
            className={(() => {
              switch (msg.status) {
                case STATUS.SENT:
                  return "message-status sent";
                case STATUS.RECEIVED:
                  return "message-status read";
                case STATUS.ERROR:
                case STATUS.SENDING:
                  return "message-status sending";
                default:
                  return "";
              }
            })()}
          />
        </p>
      </div>
    );
  };

  return (
    <div>
      {msg.status === STATUS.SENDING ? renderBodySending() : renderBodyFile()}
      {renderFooter()}
    </div>
  );
}
