import { useCallback, useContext, useEffect } from "react";
import { AudioRecorderContext } from "src/hook/audioRecorder/AudioRecorderContext";

export function useAudioRecorderTools() {
  const { recorder, recorderHook, isRecordingCompatible } =
    useContext(AudioRecorderContext);

  const startRecording = async () => recorder.startRecording();
  const stopRecording = async () => recorder.stopRecording();
  const cancelRecording = async () => recorder.cancelRecording();

  const handleSendMessage = useCallback(async () => {
    await recorder.sendResult(recorderHook.result);
  }, [recorder, recorderHook.result]);

  useEffect(() => {
    if (recorderHook.isAfterOnStop && recorderHook.result) handleSendMessage();
  }, [recorderHook.isAfterOnStop, recorderHook.result, handleSendMessage]);

  const isRecording = recorderHook.isRecording;

  return {
    isRecording,
    startRecording,
    stopRecording,
    cancelRecording,
    isRecordingCompatible,
  };
}
