import { useState, useRef } from "react";

const useTimer = (initialState = 0) => {
  const [currentTimer, setCurrentTimer] = useState(initialState);
  const [timerIsActive, setTimerIsActive] = useState(false);
  const [timerIsPaused, setTimerIsPaused] = useState(false);
  const countRef = useRef(null);

  const startTimerCount = () => {
    setTimerIsActive(true);
    setTimerIsPaused(true);
    countRef.current = setInterval(() => {
      setCurrentTimer((timer) => timer + 1);
    }, 1000);
  };

  const pauseTimerCount = () => {
    clearInterval(countRef.current);
    setTimerIsPaused(false);
  };

  const resumeTimerCount = () => {
    setTimerIsPaused(true);
    countRef.current = setInterval(() => {
      setCurrentTimer((timer) => timer + 1);
    }, 1000);
  };

  const resetTimerCount = () => {
    clearInterval(countRef.current);
    setTimerIsActive(false);
    setTimerIsPaused(false);
    setCurrentTimer(0);
  };

  return {
    currentTimer,
    timerIsActive,
    timerIsPaused,
    startTimerCount,
    pauseTimerCount,
    resumeTimerCount,
    resetTimerCount,
  };
};

export default useTimer;
