import { useState, useMemo } from "react";
import { useSpeechRecognition } from "react-speech-recognition";

navigator.getMedia =
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.msGetUserMedia;

export function useBrowserSupport() {
  const { browserSupportsSpeechRecognition, isMicrophoneAvailable } =
    useSpeechRecognition();

  const hasSupportGetMedia = !!navigator.getMedia;

  const [hasSupportGetUserMedia, setHasSupportGetUserMedia] = useState(
    !MediaRecorder.notSupported &&
      !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
  );

  const hasSupportSpeechText = useMemo(
    () =>
      "webkitSpeechRecognition" in window && browserSupportsSpeechRecognition,
    [browserSupportsSpeechRecognition]
  );

  const setNotRecordingCompatible = () => {
    setHasSupportGetUserMedia(false);
  };

  const hasSupportMedia = useMemo(
    () => hasSupportGetUserMedia || hasSupportGetMedia,
    [hasSupportGetMedia, hasSupportGetUserMedia]
  );

  const isRecordingCompatible = useMemo(
    () =>
      hasSupportSpeechText
        ? hasSupportMedia && isMicrophoneAvailable
        : hasSupportMedia,
    [hasSupportMedia, hasSupportSpeechText, isMicrophoneAvailable]
  );

  return {
    hasSupportGetUserMedia,
    hasSupportGetMedia,
    hasSupportSpeechText,
    isRecordingCompatible,
    setNotRecordingCompatible,
  };
}
