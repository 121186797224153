import { format, parseISO } from "date-fns";

export const formatTime = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  if (getHours !== "00") {
    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  }

  return `${getMinutes} : ${getSeconds}`;
};

export const groupMessagesByTimestamp = (allMessages) =>
  allMessages.reduce((groups, message) => {
    let groupMessageDate = "";

    if (typeof message.timestamp === "number") {
      groupMessageDate = format(new Date(message.timestamp), "yyyy-MM-dd");
    } else {
      const messageDate = parseISO(message.timestamp);
      groupMessageDate = format(messageDate, "yyyy-MM-dd");
    }

    if (!groups[groupMessageDate]) groups[groupMessageDate] = [];
    groups[groupMessageDate].push(message);

    return groups;
  }, {});
