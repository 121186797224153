export abstract class AbstractAudioRecorderMedia {
  abstract name: string;
  protected handleSend;
  protected recorderHook;
  protected localStream;
  protected recorder: MediaRecorder | null = null;
  protected browserHandle;

  constructor(recorderHook, handleSend, browserHandle) {
    this.recorderHook = recorderHook;
    this.handleSend = handleSend;
    this.browserHandle = browserHandle;
  }

  protected setIsAfterOnStop = (value) => {
    this.recorderHook.setIsAfterOnStop(value);
    return this;
  };

  protected setIsRecording = (value) => {
    this.recorderHook.setIsRecording(value);
    return this;
  };

  protected setResult = (value) => {
    this.recorderHook.setResult(value);
    return this;
  };

  abstract startRecording(): Promise<void>;

  protected onStart = () => {
    this.setIsRecording(true);
  };

  protected onDataAvailable = (e) => {
    this.setResult(e.data);
  };

  protected onStop = () => {
    this.setIsRecording(false);
    this.setIsAfterOnStop(true);
  };

  protected callbackSendResult = (result) => {
    const self = this;
    return new Promise((resolve, reject) => {
      self.handleSend(result, (response) => {
        !!response ? resolve(response) : reject(response);
      });
    });
  };

  sendResult = async (result) => {
    try {
      this.setResult(null);
      return await this.callbackSendResult(result);
    } catch (error) {
      console.log(error);
      this.resetRecording();
    }
  };

  stopRecording() {
    try {
      if (!!this.recorder && this.recorder.state !== "inactive")
        this.recorder.stop();
      if (!!this.localStream) this.localStream.getTracks()[0].stop();
    } catch (error) {
      this.resetRecording();
    }
  }

  cancelRecording() {
    const self = this;

    if (
      this.recorder instanceof MediaRecorder &&
      this.recorder.state !== "inactive"
    ) {
      this.recorder.removeEventListener("stop", this.onStop);
      this.recorder.removeEventListener("dataavailable", this.onDataAvailable);
      this.recorder.addEventListener("stop", () => {
        self.resetRecording();
      });
      this.recorder.stop();
    } else {
      this.resetRecording();
    }
  }

  protected resetRecording() {
    this.setIsRecording(false).setResult(null).setIsAfterOnStop(false);
    !!this.localStream && this.localStream.getTracks()[0].stop();
  }
}
