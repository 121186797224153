import React, { useCallback, useEffect, useRef, useState } from "react";
import "./SendOptMenu.scss";
import {
  MdLibraryBooks,
  MdPhoto,
  MdMap,
  MdCameraEnhance,
  MdVideoLibrary,
} from "react-icons/md";
import CameraScreen from "./OptButtons/CameraScreen";
import { FILETYPE } from "../../contexts/store/appReducer";
import getTheme from "src/themes/getTheme";
import { isIOS } from "react-device-detect";

export default function SendOptMenu(props) {
  const { sendFile, handleAttachClick, onLocation, isRecordingCompatible } =
    props;
  const [showCameraScreen, setShowCameraScreen] = useState(false);
  const node = useRef();

  const handleClick = useCallback(
    (e) => {
      if (node.current.contains(e.target)) return;
      handleAttachClick();
    },
    [handleAttachClick]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClick]);

  const handleSendFile = async (file, fileType) => {
    let location = undefined;
    if ("true" === process.env.REACT_APP_LOCATION_IMAGE) {
      location = await getLocation();
    }

    sendFile(file, fileType, location);
    handleAttachClick();
  };

  const handleSendLocation = async () => {
    const location = await getLocation();
    onLocation(location);
    handleAttachClick();
  };

  const getLocation = async () => {
    return new Promise((res, rej) => {
      navigator.geolocation.getCurrentPosition((location) => res(location));
    });
  };

  const theme = getTheme();

  return (
    <>
      {showCameraScreen ? (
        <CameraScreen
          handleAttachClick={handleAttachClick}
          sendFile={(e) => handleSendFile(e, FILETYPE.IMAGE)}
        />
      ) : (
        <></>
      )}
      <div className="send-opt-menu">
        <div
          className={`send-opt-menu__content ${
            theme?.options?.hideActions.length > 2 ? "two-items " : ""
          }`}
          ref={node}
        >
          <div className="send-opt-menu__content__btn">
            <label
              className="optButtons document-hover"
              htmlFor="input-file"
              style={{ background: "#dc143c" }}
            >
              <MdLibraryBooks />
            </label>
            <input
              className="optButtons"
              id="input-file"
              accept={isIOS ? "*/*" : ".pdf,.doc,.docx,.log,.xls,.xlsx"}
              style={{ display: "none" }}
              onChange={(e) => handleSendFile(e.target.files[0], FILETYPE.DOC)}
              type="file"
            />
            <span>Documento</span>
          </div>

          {!theme?.options?.hideActions.includes("gallery") && (
            <div className="send-opt-menu__content__btn">
              <label
                className="optButtons gallery-hover"
                htmlFor="input-image"
                style={{ background: "#3a59be" }}
              >
                <MdPhoto />
              </label>
              <input
                id="input-image"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) =>
                  handleSendFile(e.target.files[0], FILETYPE.IMAGE)
                }
                type="file"
              />
              <span>Galeria</span>
            </div>
          )}

          {!theme?.options?.hideActions.includes("camera") &&
            isRecordingCompatible && (
              <div className="send-opt-menu__content__btn">
                <div
                  className="optButtons camera-hover"
                  htmlFor="input-camera"
                  style={{ background: "#3abe62" }}
                  onClick={() => {
                    setShowCameraScreen(true);
                    document.removeEventListener("click", handleClick);
                  }}
                >
                  <MdCameraEnhance />
                </div>
                <span>Câmera</span>
              </div>
            )}

          {!theme?.options?.hideActions.includes("video") && (
            <div className="send-opt-menu__content__btn">
              <label
                className="optButtons video-hover"
                htmlFor="input-video"
                style={{ background: "#e3478c" }}
              >
                <MdVideoLibrary />
              </label>
              <input
                id="input-video"
                accept="video/*"
                style={{ display: "none" }}
                onChange={(e) =>
                  handleSendFile(e.target.files[0], FILETYPE.VIDEO)
                }
                type="file"
              />
              <span>Video</span>
            </div>
          )}

          {!theme?.options?.hideActions.includes("location") && (
            <div className="send-opt-menu__content__btn">
              <div
                className="optButtons location-hover"
                htmlFor="input-location"
                style={{ background: "#f35a46" }}
                onClick={handleSendLocation}
              >
                <MdMap />
              </div>
              <span>Localização</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
