import React, { useCallback, useState } from "react";
import { isIOS, isMobile } from "react-device-detect";
import { MdClose, MdDone, MdMic } from "react-icons/md";

const SendButtons = (props) => {
  const { isRecording, startRecording, stopRecording, cancelRecording, timer } =
    props;

  const [ isButtonActive, setIsButtonActive ] = useState(false)

  // Desktop Audio Button
  const renderMicOpt = useCallback(() => {
    if (isRecording) {
      return (
        <>
          <button className="send" onClick={() => cancelRecording()}>
            <div className="circle audio-cancel">
              <MdClose />
            </div>
          </button>
          <p>
            <span>{timer}</span>
          </p>
          <button className="send" onClick={() => stopRecording()}>
            <div className="circle audio-send">
              <MdDone />
            </div>
          </button>
        </>
      );
    } else {
      return (
        <button className="send" onClick={() => startRecording()}>
          <div className="circle">
            <MdMic />
          </div>
        </button>
      );
    }
  }, [cancelRecording, isRecording, startRecording, stopRecording, timer]);

  const handleTouchStart = useCallback(() => {
    startRecording();
    setIsButtonActive(true);
  }, [startRecording]);

  const handleTouchEnd = useCallback(() => {
    stopRecording();
    setIsButtonActive(false);
  }, [stopRecording]);

  const renderBody = useCallback(() => {
    return (isMobile || window.ReactNativeWebView) && !isIOS ? (
      <button
        className={`send ${isButtonActive ? 'active' : ''}`}
        onTouchStart={() => handleTouchStart()}
        onTouchEnd={() => handleTouchEnd()}
      >
        <div className="circle">
          <MdMic />
        </div>
      </button>
    ) : (
      <div className="container-audio">{renderMicOpt()}</div>
    );
  }, [renderMicOpt, handleTouchEnd, handleTouchStart, isButtonActive]);

  return renderBody();
};

export default React.memo(SendButtons);
