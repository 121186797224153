import { isSafari } from "react-device-detect";
import { toast } from "react-toastify";
import RecordRTC from "recordrtc";
import { AbstractAudioRecorderMedia } from "./AbstractAudioRecorderMedia";

export class AudioRecorderGetUserMedia extends AbstractAudioRecorderMedia {
  name = "AudioRecorderGetUserMedia";
  recorderInstance: RecordRTC | null = null;

  async startRecording() {
    try {
      this.resetRecording();
      const self = this;

      if (
        !self.recorderHook.isRecording &&
        self.recorder?.state !== "recording"
      ) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        self.localStream = stream;

        if (isSafari) {
          self.recorderInstance = new RecordRTC(stream, {
            type: "audio",
            mimeType: 'audio/wav',
            recorderType: RecordRTC.StereoAudioRecorder,
            disableLogs: true,
          });

          self.recorderInstance?.startRecording()
          self.onStart()
          
        } else {
          self.recorder = new MediaRecorder(stream);        
          self.recorder.addEventListener("dataavailable", self.onDataAvailable);
          self.recorder.addEventListener("stop", self.onStop);
          self.recorder.addEventListener("start", self.onStart);
  
          self.recorder.start();
        }
      }
    } catch (error: any) {
      console.log(error);
      this.resetRecording();

      if (error.message === "getUserMedia is not implemented in this browser") {
        this.browserHandle.setNotRecordingCompatible();
        toast.error("Sem suporte para gravação de audio.");
      }

      if (error.message === "Permission denied") {
        toast.error("Sem permissão para gravar.");
      }
    }
  }

  stopRecording() {
    try {
      if (this.recorderInstance) {
        this.recorderInstance?.stopRecording(() => {
          const audioBlob = this.recorderInstance.getBlob();
          this.onDataAvailable({ data: audioBlob });

          this.onStop();
          this.recorderInstance = null;
        });
      } else if (this.recorder && this.recorder.state !== "inactive") {
        this.recorder.stop();
      }

      if (this.localStream) this.localStream.getTracks()[0].stop();
    } catch (error) {
      this.resetRecording();
    }
  }
}
