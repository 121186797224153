import React, { useRef, useEffect, useState } from "react";
import { format, isToday, isYesterday, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { connect } from "react-redux";

import { ACTIONS, CONTENTTYPE } from "../../contexts/store/appReducer";
import PropTypes from "prop-types";
import "react-image-lightbox/style.css";
import "./MessageList.scss";
import {
  ImageCase,
  ListCase,
  TextCase,
  AudioCase,
  CarouselCase,
  SendLocation,
  Document,
} from "../MessageCases";
import { Payload } from "../Payload";
import { MessageItem } from "./MessageItem";
import { VideoCase } from "../MessageCases/Video/Video";

const renderSuggestions = (dispatch, msg) => {
  const suggestionsFromJson = () => {
    try {
      const json = JSON.parse(msg.text);
      return json?.suggestions ?? [];
    } catch (error) {
      return [];
    }
  };

  const suggestions = msg.suggestions || suggestionsFromJson();

  dispatch([
    {
      type: ACTIONS.UPDATE_MESSAGE_CHOICE,
      _id: msg.id,
      messages: [
        {
          suggestions: suggestions ?? [],
        },
      ],
    },
  ]);
};

const MessagesList = (props) => {
  const secDivRef = useRef(null);
  const [showImage, setShowImage] = useState(null);
  const [itemSelected, setItemSelected] = useState("");
  const [isOpenIframe, setIsOpenIframe] = useState(false);
  const [openCards, setOpenCards] = useState(false);

  useEffect(() => {
    scrollDown();
  }, [props.messages]);

  const scrollDown = () => {
    if (secDivRef.current) {
      setTimeout(() => {
        secDivRef.current.scroll(0, secDivRef.current.scrollHeight + 100);
      }, 10);
    }
  };

  const renderMessage = ({ msg, isLastMessage, isSecondeLastMessage }) => {
    setOpenCards(false);

    switch (msg.contentType) {
      case CONTENTTYPE.VIDEO:
        return <VideoCase {...msg} />;
      case CONTENTTYPE.PAYLOAD:
        setOpenCards(true);
        return (
          <Payload
            msg={msg}
            isLastMessage={isLastMessage}
            sendMessage={props.onMessage}
            sendRegisterDevice={props.onRegisterDevice}
          />
        );
      case CONTENTTYPE.AUDIO:
        return <AudioCase msg={msg} />;
      case CONTENTTYPE.IMAGE:
        return (
          <ImageCase
            msg={msg}
            scrollDown={scrollDown}
            showImage={showImage}
            setShowImage={setShowImage}
          />
        );

      case CONTENTTYPE.LIST:
        return (
          <ListCase
            msg={msg}
            itemSelected={itemSelected}
            setItemSelected={setItemSelected}
            onMessage={props.onMessage}
            lastMessage={isLastMessage}
          />
        );

      case CONTENTTYPE.LOCATION:
        return <SendLocation msg={msg} />;

      case CONTENTTYPE.FILE:
        return <Document msg={msg} />;

      case CONTENTTYPE.CAROUSEL:
        return (
          <CarouselCase
            msg={msg}
            itemSelected={itemSelected}
            setItemSelected={setItemSelected}
            onMessage={props.onMessage}
            lastMessage={isLastMessage || isSecondeLastMessage}
          />
        );
      case CONTENTTYPE.CHAT_START:
      case CONTENTTYPE.CHAT_END:
        return null;

      case CONTENTTYPE.TEXT:
      default:
        msg.historic && isLastMessage && renderSuggestions(props.dispatch, msg);
        return (
          <TextCase
            msg={msg}
            lastMessage={isLastMessage}
            setIsOpenIframe={setIsOpenIframe}
          />
        );
    }
  };

  const nowDateToMessage = format(new Date(), "yyyy-MM-dd");

  const fullItems = () => {
    const days = Object.keys(props.groupMessages);

    return days.map((day) => {
      const messages = props.groupMessages[day];

      const dateDay = parseISO(day);
      const currentDay = new Date(`${day}T00:00`);

      let phrase = "";

      if (isToday(dateDay)) {
        phrase = "Hoje";
      } else if (isYesterday(dateDay)) {
        phrase = "Ontem";
      } else {
        phrase = format(currentDay, "dd 'de' MMMM 'de' yyyy", {
          locale: ptBR,
        });
      }

      return (
        <>
          <div className="message center">
            <div className="message-balloon message-balloon-time false">
              <p className="message-text message-time">{phrase}</p>
            </div>
          </div>

          {messages.map((msg, i) => {
            const listOrCarousel = [
              CONTENTTYPE.LIST,
              CONTENTTYPE.CAROUSEL,
            ].includes(msg.contentType);
            return (
              <MessageItem
                key={i}
                index={i}
                listOrCarousel={listOrCarousel}
                msg={msg}
                isDateNow={phrase === "Hoje"}
                nowDateToMessage={nowDateToMessage}
                renderMessage={renderMessage}
                groupMessages={props.groupMessages}
              />
            );
          })}
        </>
      );
    });
  };

  return (
    <>
      <div
        className={`messages-container ${openCards ? "open-cards" : ""} ${
          props.extraMargin
            ? "messages-extra messages-container-buttons-size"
            : "messages-container-normal-size"
        } ${isOpenIframe ? "open-iframe" : ""}`}
        ref={secDivRef}
      >
        <div className="messages">{fullItems()}</div>
      </div>
    </>
  );
};

MessagesList.propTypes = {
  messages: PropTypes.array.isRequired,
  extraMargin: PropTypes.bool.isRequired,
};

export default connect()(MessagesList);
