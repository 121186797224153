import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import DOMPurify from "dompurify";

import { formatTitle } from "../../../utils/formatTitle";
import "./ListModalDetails.scss";
import emptyHtml from "./emptyHtml";

export default function ListModalDetails(props) {
  const { itemDetails, closeDetails } = props;

  const cleanHTML = DOMPurify.sanitize(itemDetails?.htmlDetails || emptyHtml, {
    USE_PROFILES: { html: true, svg: true, svgFilters: true },
    FORBID_TAGS: ["script"],
    FORCE_BODY: true,
  });

  return (
    <div className="list-modal-details-container">
      <div className="list-modal-details-header">
        <button type="button" onClick={closeDetails}>
          <BsArrowLeft size={30} />
        </button>
        <h1>{formatTitle(itemDetails?.title)}</h1>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: cleanHTML,
        }}
      ></div>
    </div>
  );
}
