const { REACT_APP_STAGE, REACT_APP_BOT } = process.env;

export const FirebaseConfig = {
  vapiKey:
    "BI4YAkJMRZUg6HHGm_KufXQggiJl6A92aaNcPzIyYbVP293dO3ezbn4pmJ62Htt4pkaia6IXXafnZPYVqeDCZ1c",
  apiKey: "AIzaSyCVpEfmIyhTgfv8Dms9BRJLQt-adCZJ2YE",
  authDomain: "notify-falazap-dev.firebaseapp.com",
  projectId: "notify-falazap-dev",
  storageBucket: "notify-falazap-dev.appspot.com",
  messagingSenderId: "818852182952",
  appId: "1:818852182952:web:c0c7743e52f1deee0dbb9d",
  // measurementId: "G-BBFQF5ECN9",
};

export const BotConfig = {
  stage: REACT_APP_STAGE ?? "dev",
  name: REACT_APP_BOT ?? "",
};
