export const isValidHref = (href) =>
  /https:\/\/bit.ly/gi.test(href) ||
  /https:\/\/aperte.me/gi.test(href) ||
  /https:\/\/dialog.fintalk.io/gi.test(href);

export const formatBreakline = (msg) => {
  (msg.match(/\\n|\\r\\n/g) || []).forEach((q) => {
    msg = msg.replace(q, q.replace(/\\n|\\r\\n/g, " <br/> "));
  });

  return msg;
};

export const formatBold = (msg) => {
  (
    msg.match(
      /(?!^|\s)?(?:\*)(.+?(?=\*))(?:\*)(?=$|\s|[!@#$%^&()\-_=+?.:;"'])/g
    ) || []
  ).forEach((q) => {
    msg = msg.replace(q, `<strong>${q.replace(/\*/g, "")}</strong>`);
  });

  return msg;
};

export const formatLink = (msg) => {
  const exp =
    /((?:https?|ftp|file):\/\/|www\.|ftp\.)(\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.]*)(\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
  if (exp.test(msg)) {
    return msg.replace(
      exp,
      '<a target="_blank" href="$1$2$3" rel="noreferrer noopener">$1$2$3</a>'
    );
  }
  return msg;
};

export const buildFormatMessage = (msg, type = "text") => {
  if (type === "cards") return msg;

  return formatMessageText(msg);
};

export const formatMessageText = (msg) => {
  msg = formatBreakline(msg);
  msg = formatBold(msg);
  msg = formatLink(msg);
  msg = formatItalic(msg);
  return msg;
};

const formatItalic = (msg) => {
  (msg.match( /(?!^|\s)?(?:_)(.+?(?=_))(?:_)(?=$|\s|[!@#$%^&()\-_=+?.:;"'])/g) || []).forEach((q) => {
    msg = msg.replace(q, `<i>${q.replace(/_/g, "")}</i>`);
  });

  return msg;
};

export const formateMessageToCard = (msg) => {
  const extractedData =
    typeof msg.text === "string" ? JSON.parse(msg.text) : msg.text;

  if (!!extractedData.text) {
    if (typeof extractedData.text === "object")
      return JSON.stringify(extractedData.text);

    return extractedData.text;
  }

  return msg.text;
};

export const validLink = (msg) => {
  const exp =
    /((?:https?|ftp|file):\/\/|www\.|ftp\.)(\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.]*)(\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
  return exp.test(msg);
};
