import React, { createContext, useMemo } from "react";

import { isIOS, isSafari } from "react-device-detect";
import {
  AudioRecorderGetUserMedia,
  AudioRecorderOldGetUserMedia,
  AudioRecorderSpeechText,
  useAudioRecorder,
  useAudioRecorderSpeechText,
} from "src/hook/audioRecorder";
import { useBrowserSupport } from "src/hook/browserSupport";

export const AudioRecorderContext = createContext();

export function AudioRecorderProvider({ children }) {
  const recorderHook = useAudioRecorder();
  const recorderHookSpeechText = useAudioRecorderSpeechText();
  const browserSupport = useBrowserSupport();

  const handleSendAudioMessage = (message, callback) => {
    message = /data:audio/i.test(message) ? message.split(",")[1] : message;
    window.asyncSendAudio(message, callback);
  };

  const handleSendTextMessage = (message, callback) => {
    window.sendTextMessage(message, callback);
  };

  const recorder = useMemo(() => {
    if ((isSafari || isIOS) && browserSupport.hasSupportGetUserMedia) {
      return new AudioRecorderGetUserMedia(
        recorderHook,
        handleSendAudioMessage,
        {
          setNotRecordingCompatible: browserSupport.setNotRecordingCompatible,
        }
      );
    }

    if (browserSupport.hasSupportSpeechText) {
      return new AudioRecorderSpeechText(
        recorderHookSpeechText,
        handleSendTextMessage
      );
    }

    if (browserSupport.hasSupportGetUserMedia) {
      return new AudioRecorderGetUserMedia(
        recorderHook,
        handleSendAudioMessage,
        {
          setNotRecordingCompatible: browserSupport.setNotRecordingCompatible,
        }
      );
    }

    return new AudioRecorderOldGetUserMedia(
      recorderHook,
      handleSendAudioMessage,
      {
        setNotRecordingCompatible: browserSupport.setNotRecordingCompatible,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AudioRecorderContext.Provider
      value={{
        isRecordingCompatible: browserSupport.isRecordingCompatible,
        recorder: recorder ?? {},
        recorderHook: browserSupport.hasSupportSpeechText && recorder.name === 'AudioRecorderSpeechText'
          ? recorderHookSpeechText
          : recorderHook,
      }}
    >
      {children}
    </AudioRecorderContext.Provider>
  );
}
