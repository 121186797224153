import React from "react";

const NotificationImageModal = ({ image, title }) => {
  if (!image) return <></>;

  return (
    <picture
      style={{
        width: "100%",
        height: "150px",
      }}
    >
      <img
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
        src={image}
        alt={title}
      />
    </picture>
  );
};

export function NotificationModal({ image, title, body }) {
  return (
    <div>
      <NotificationImageModal image={image} title={title} />
      <h4>{title}</h4>
      <p>{body}</p>
    </div>
  );
}
