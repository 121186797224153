import React, { useEffect, useState } from "react";
import "./Header.scss";
import changeTheme from "../../utils/changeTheme";
import { IoMdSunny, IoMdMoon, IoMdClose } from "react-icons/io";

import defineTheme from "../../themes/getTheme";

const Header = (_props) => {
  const currentTheme = defineTheme();
  const prefersDarkTheme =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? true
      : false;

  const getTheme = JSON.parse(localStorage.getItem("dark_theme"));

  const hasDarkTheme =
    !!currentTheme && !!currentTheme.dark && currentTheme.name !== "Namu";

  const addToHomeScreenClass = `a2hs-button ${
    !hasDarkTheme ? "a2hs-button-without-dark-theme" : ""
  }`;

  const [darkTheme, setDarkTheme] = useState(
    hasDarkTheme && getTheme === null ? prefersDarkTheme : getTheme
  );

  const setTheme = (theme) => localStorage.setItem("dark_theme", theme);
  const isDev = process.env.REACT_APP_STAGE === "dev";

  const [showStorage, setShowStorage] = useState(false);

  useEffect(() => {
    if (getTheme === null) setTheme(prefersDarkTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => changeTheme(darkTheme), [darkTheme]);

  const handleClick = () => {
    setDarkTheme(!darkTheme);
    setTheme(!getTheme);
  };

  return (
    <>
      <div className="header-bar">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="header-photo">
            <img
              src={
                darkTheme
                  ? currentTheme.url
                  : !!currentTheme.url_dark
                  ? currentTheme.url_dark
                  : currentTheme.url
              }
              alt={currentTheme.name}
            />
          </div>
          {currentTheme.name && (
            <div className="header-name">
              <span>
                {currentTheme.name === "Namu" ? "Manu" : currentTheme.name}
              </span>
            </div>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <button className={addToHomeScreenClass}>
            Adicionar à tela inicial
          </button>

          {hasDarkTheme && (
            <div className="change-theme-btn-container">
              <button type="button" onClick={handleClick}>
                {darkTheme ? <IoMdSunny /> : <IoMdMoon />}
              </button>
            </div>
          )}
        </div>
      </div>
      {showStorage && isDev && (
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 998,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#ececec",
              padding: 16,
              width: "80%",
              height: "80%",
              zIndex: 999,
              borderRadius: 16,
            }}
          >
            <button
              style={{ borderStyle: "none" }}
              type="button"
              onClick={() => setShowStorage(false)}
            >
              <IoMdClose size={24} />
            </button>

            <h3>Dados em Storage (only dev)</h3>
            <strong>Session storage</strong>
            <p>initialMessage: {sessionStorage.getItem("initialMessage")}</p>
            <br />
            <strong>Local storage</strong>
            <p>bid: {localStorage.getItem("bid")}</p>
            <p>pid: {localStorage.getItem("pid")}</p>
            <p>zarah-data: {localStorage.getItem("zarah-data")}</p>
            <p>token: {localStorage.getItem("token")}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
