export function clearCacheAndReload() {
  if (caches) {
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  window.location.reload(true);
}

export function clearLocalStorage() {
  localStorage.removeItem('token');
  window.location.reload(true);
}
