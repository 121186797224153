import React from "react";
import "./Loading.scss";

export const LoadingCircle = () => {
  return (
    <div className={`loading-container`}>
      <div className="loader"></div>
    </div>
  );
};

export default LoadingCircle;
