import { useEffect } from "react";

export function usePostMessage({ displayMessage }) {
  const isFunction = () => typeof displayMessage === "function";

  useEffect(() => {
    if (isFunction()) window.addEventListener("message", displayMessage, false);

    return () => {
      if (isFunction())
        window.removeEventListener("message", displayMessage, false);
    };
  });

  return {
    isFunction,
  };
}
