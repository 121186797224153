import React from "react";

export function ListModalLoading() {
  return (
    <div>
      <img
        src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"
        alt=""
        style={{
          width: "40px",
          height: "40px",
        }}
      />
    </div>
  );
}
