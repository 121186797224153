import axios, { AxiosInstance } from "axios";
import { OrchestratorConfigs } from "../config/orchestratorConfigs";

class OrchestratorService {
  private api: AxiosInstance;
  private static _instance: OrchestratorService;

  constructor() {
    this.api = axios.create({ baseURL: OrchestratorConfigs.baseURL });
  }

  static get Instance() {
    if (!OrchestratorService._instance)
      OrchestratorService._instance = new OrchestratorService();
    return OrchestratorService._instance;
  }

  async sendNotifyWebhook(data) {
    return this.api.post(
      OrchestratorConfigs.resources.notifywebhook.post,
      data,
      {}
    );
  }
}

export default OrchestratorService;
