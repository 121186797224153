import { useCallback, useEffect, useMemo, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  Messaging,
  onMessage,
} from "firebase/messaging";
import { toast } from "react-toastify";

import { FirebaseConfig } from "../../config";
import {
  FirebaseNotificationPayload,
  FirebaseNotificationProps,
} from "./types";
import { addDays, isAfter } from "date-fns";

const NotificationPermissions = {
  DENIED: "denied",
  GRANTED: "granted",
  DEFAULT: "default",
};

export function useFirebaseNotification({
  handleOnMessage,
}: FirebaseNotificationProps): FirebaseNotificationPayload {
  const [isNotificationsAllowed, setIsNotificationsAllowed] = useState(false);
  const [messingToken, setMessingToken] = useState(
    localStorage.getItem("firebaseMessingToken")
  );
  const [isNotificationAuthorized, setIsNotificationAuthorized] =
    useState(false);

  const firebaseApp = useMemo(() => initializeApp(FirebaseConfig), []);
  const [messaging, setMessaging] = useState<Messaging | null>(null);
  const [requestTokenTries, setRequestTokenTries] = useState(0);
  const isIframe = window.top !== window.self;

  const [supported, setSupported] = useState(
    !!localStorage.getItem("supported") &&
      localStorage.getItem("supported") === "true"
  );

  useEffect(() => {
    const requestGetMessaging = async () => {
      if (await isSupported()) {
        setMessaging(getMessaging(firebaseApp));
        setSupported(true);
        localStorage.setItem("supported", "true");
      } else {
        setSupported(false);
        localStorage.removeItem("supported");
      }
    };

    requestGetMessaging();
  }, [firebaseApp]);

  useEffect(() => {
    const localTokenExpirationDate = localStorage.getItem(
      "firebaseMessingTokenExperationDate"
    );
    const localToken = localStorage.getItem("firebaseMessingToken");
    if (localToken && localTokenExpirationDate) {
      const parsedExpirationDate = new Date(
        JSON.parse(localTokenExpirationDate)
      );
      if (isAfter(new Date(), parsedExpirationDate)) {
        updateToken();
      }
    }
    //eslint-disable-next-line
  }, [messaging]);

  async function updateToken() {
    if (messaging) {
      const currentToken = await getToken(messaging, {
        vapidKey: FirebaseConfig.vapiKey,
      });
      localStorage.setItem("firebaseMessingToken", currentToken);
      const tokenInvalidationDate = addDays(new Date(), 30);
      localStorage.setItem(
        "firebaseMessingTokenExperationDate",
        JSON.stringify(tokenInvalidationDate)
      );
      setTimeout(() => {
        window.sendRegisterDevice(currentToken);
      }, 1000);
    }
  }

  const onReceivedMessage = useCallback(
    (messaging) => {
      onMessage(messaging, (payload) => {
        if (!!payload.notification && payload.notification.body) {
          handleOnMessage(payload);
        }
      });
    },
    [handleOnMessage]
  );

  useEffect(() => {
    if (!!messaging && !!messingToken && typeof messingToken === "string") {
      onReceivedMessage(messaging);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messaging, onReceivedMessage]);

  useEffect(() => {
    if (!window.Notification || !isNotificationAuthorized) return;

    setIsNotificationsAllowed(false);

    if (!!Notification && !!Notification.permission && !isIframe) {
      switch (Notification.permission) {
        case NotificationPermissions.GRANTED:
          setIsNotificationsAllowed(true);
          break;
        case NotificationPermissions.DENIED:
          toast("Permissão para Notificação não concedida");
          break;
        case NotificationPermissions.DEFAULT:
        default:
          Notification.requestPermission().then(function (permission) {
            setIsNotificationsAllowed(permission === "granted");
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationAuthorized]);

  const resetNotification = useCallback(() => {
    localStorage.setItem("firebaseMessingToken", "");
    setMessingToken("");
  }, []);

  const requestToken = useCallback(async () => {
    if (!!messaging && requestTokenTries < 3) {
      try {
        const currentToken = await getToken(messaging, {
          vapidKey: FirebaseConfig.vapiKey,
        });

        if (currentToken) {
          localStorage.setItem("firebaseMessingToken", currentToken);
          const tokenInvalidationDate = addDays(new Date(), 30);
          localStorage.setItem(
            "firebaseMessingTokenExperationDate",
            JSON.stringify(tokenInvalidationDate)
          );
          setMessingToken(currentToken);
          onReceivedMessage(messaging);
          window.sendRegisterDevice(currentToken);
        } else {
          toast.warning(
            "Nenhum token de registro disponível. Solicite permissão para gerar um."
          );
        }
      } catch (e) {
        console.log(e);
        setRequestTokenTries((prev) => {
          console.log(prev + 1);
          return prev + 1;
        });
        requestToken();
      }
    }
  }, [messaging, onReceivedMessage, requestTokenTries]);

  useEffect(() => {
    if (!!isNotificationsAllowed) {
      requestToken().catch((error) => {
        toast.error(error.message ?? "Ocorreu um erro ao recuperar o token.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationsAllowed]);

  return {
    isNotificationsAllowed,
    messingToken,
    isNotificationAuthorized,
    setIsNotificationAuthorized,
    resetNotification,
    supported,
  };
}
