const stage = process.env.REACT_APP_STAGE ?? "dev";
const baseURL = `https://api-${process.env.REACT_APP_BOT}.fintalk.io/${stage}`;

export const OrchestratorConfigs = {
  baseURL: baseURL,
  resources: {
    notifywebhook: {
      post: `/notifywebhook`,
    },
  },
};
