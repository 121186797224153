import { useState } from "react";

export function useAudioRecorder() {
  const [isRecording, setIsRecording] = useState(false);
  const [isAfterOnStop, setIsAfterOnStop] = useState(false);
  const [result, setResult] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  return {
    isRecording,
    result,
    fileUrl,
    isAfterOnStop,
    setIsAfterOnStop,
    setResult,
    setFileUrl,
    setIsRecording,
  };
}
