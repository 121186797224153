import getTheme from "../themes/getTheme";

export const isDarkTheme = () => {
  const theme = getTheme();

  if (theme.name === "Namu") return false;

  const prefersDarkTheme =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? true
      : false;
  const currentTheme = JSON.parse(localStorage.getItem("dark_theme"));
  return currentTheme === null ? prefersDarkTheme : currentTheme;
};

const defaultTheme = {
  name: "Namu",
  url: "./android-chrome-192x192.png",
  url_dark: "./android-chrome-192x192-dark.png",
  moneyLocale: "pt-BR",
  moneyCurrency: "BRL",
  light: {
    header: {
      backgroundColor: "#f8f8f8",
      color: "#505050",
    },
    footer: {
      background: "#2b383e",
      color: "#2b383e",
      btnSend: {
        backgroundColor: "#483b8a",
        color: "#fff",
      },
    },
    messages: {
      background: `url("../../assets/images/background.png")`,
      userBallon: {
        backgroundColor: "#009400",
        color: "rgba(0,0,0,0.68)",
      },
      botBallon: {
        backgroundColor: "#009400",
        color: "rgba(255, 255, 255, 0.8)",
      },
      sendDate: {
        backgroundColor: "#009400",
        color: "rgba(255, 255, 255, 0.8)",
      },
      link: "red",
    },
  },
};

export default function changeTheme(darkTheme) {
  const doc = document.documentElement;
  const theme = getTheme();

  const isDarkTheme = darkTheme && darkTheme !== 'false' && !!theme && !!theme.dark;
  const isOldTheme =
    !!theme &&
    (isDarkTheme
      ? !!theme.dark && !theme.dark.header
      : !!theme.light && !theme.light.header);

  let currentTheme = Object.assign(
    {},
    defaultTheme.light,
    isDarkTheme ? theme.dark : theme.light
  );

  if (isOldTheme) {
    currentTheme.font = `14px 'Roboto', sans-serif`;

    currentTheme.header.backgroundColor = isDarkTheme ? "#243137" : "#f8f8f8";
    currentTheme.header.color = isDarkTheme ? "#ffffffad" : "#000000ad";

    currentTheme.messages.background = isDarkTheme
      ? `url("https://i.ibb.co/WxMmMpJ/background-dark.png")`
      : `url("https://i.ibb.co/NWF5Ptm/background.png")`;

    currentTheme.messages.userBallon.backgroundColor = isDarkTheme
      ? theme.dark.color
      : theme.light.color;

    currentTheme.messages.userBallon.color = "rgba(255, 255, 255, 0.8)";

    currentTheme.messages.botBallon.backgroundColor = isDarkTheme
      ? `#2b383e`
      : `#f6f6f6`;

    currentTheme.messages.botBallon.color = isDarkTheme
      ? `rgba(255, 255, 255, 0.8)`
      : `rgba(0,0,0,0.68)`;

    currentTheme.messages.sendDate.color = isDarkTheme
      ? theme.dark.messages?.sendDate?.color || `rgba(255, 255, 255, 0.8)`
      : theme.light.messages?.sendDate?.color || `rgba(0,0,0,0.68)`;

    currentTheme.messages.sendDate.backgroundColor = isDarkTheme
      ? theme.dark.messages?.sendDate?.backgroundColor || "#2b383e"
      : theme.light.messages?.sendDate?.backgroundColor || "#f6f6f6";

    currentTheme.footer.btnSend.backgroundColor =
      currentTheme.messages.userBallon.backgroundColor;

    currentTheme.footer.backgroundColor = isDarkTheme ? "#2b383e" : "#f6f6f6";
    currentTheme.footer.color = isDarkTheme
      ? `rgba(255, 255, 255, 0.8)`
      : `rgba(0,0,0,0.68)`;

    currentTheme.messages.link = isDarkTheme
      ? theme.dark.linkColor
      : theme.light.linkColor;
  }

  doc.style.setProperty("--font", currentTheme.font);

  doc.style.setProperty(
    "--header-background-color",
    currentTheme.header.backgroundColor
  );
  doc.style.setProperty("--header-color", currentTheme.header.color);

  doc.style.setProperty(
    "--footer-btn-send-background-color",
    currentTheme.footer.btnSend.backgroundColor
  );
  doc.style.setProperty(
    "--footer-btn-send-color",
    currentTheme.footer.btnSend.color
  );

  doc.style.setProperty(
    "--footer-background-color",
    currentTheme.footer.backgroundColor
  );

  doc.style.setProperty("--footer-color", currentTheme.footer.color);

  doc.style.setProperty(
    "--messages-background",
    currentTheme.messages.background
  );

  doc.style.setProperty(
    "--messages-user-ballon-background-color",
    currentTheme.messages.userBallon.backgroundColor
  );
  doc.style.setProperty(
    "--messages-bot-ballon-background-color",
    currentTheme.messages.botBallon.backgroundColor
  );

  doc.style.setProperty(
    "--messages-time-ballon-color",
    currentTheme.messages.sendDate?.color
  );

  doc.style.setProperty(
    "--messages-time-ballon-background-color",
    currentTheme.messages.sendDate?.backgroundColor
  );

  doc.style.setProperty(
    "--messages-user-ballon-color",
    currentTheme.messages.userBallon.color
  );
  doc.style.setProperty(
    "--messages-bot-ballon-color",
    currentTheme.messages.botBallon.color
  );

  doc.style.setProperty("--messages-link", currentTheme.messages.link);
	
  doc.style.setProperty("--messages-font-weight", currentTheme.messages.fontWeight);
}
