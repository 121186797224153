import React from "react";
import "./Carousel.scss";
import getTheme from "../../../themes/getTheme";
import parse from "html-react-parser";

export default function Carousel(props) {
  const { msg, setItemSelected, onMessage, lastMessage } = props;
  const currentTheme = getTheme();

  const productCarousel =
    typeof msg.text === "string" ? JSON.parse(msg.text) : msg.text;
  const convertPrice = (num) =>
    num.toLocaleString(currentTheme.moneyLocale, {
      style: "currency",
      currency: currentTheme.moneyCurrency,
    });

  const formatBold = (msg) => {
    (msg.match(/\*.*?\*/g) || []).forEach((q) => {
      msg = msg.replace(q, `<strong>${q.replace(/\*/g, "")}</strong>`);
    });

    return msg;
  };
  return (
    <>
      <div className="message-list">
        {productCarousel.map((m, i) => {
          const { price } = m;

          const description = !!m.description
            ? parse(formatBold(m.description))
            : "";

          return (
            <div
              key={i}
              className={`message-list__item`}
              onClick={(_e) => {
                if (!!lastMessage) {
                  setItemSelected(m.title);
                  onMessage(m.title);
                }
              }}
            >
              <div className="message-list__item__image">
                {!!price && (
                  <div className="message-list__item__image__price">
                    {Number.isFinite(price) ? convertPrice(price) : price}
                  </div>
                )}
                <img src={m.image} alt={m.title} />
              </div>
              <div className="message-list__item__info">
                <div className="message-list__item__info__title">
                  <b>{m.title}</b>
                </div>
                {!!description && (
                  <div className="message-list__item__info__description">
                    <div>{description}</div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
