import React from "react";
import "./NotAuthorized.scss";

const NotAuthorized = ({ getMessages }) => {
  return (
    <div className="notauthorized-container">
      <div className="dog-image"></div>

      <h3 className="notauthorized-title">
        Opa, acho que encontrei um problema de acesso aqui. Desculpa...
      </h3>
      <button
        className="notauthorized-button"
        onClick={() => getMessages(true)}
      >
        Tentar novamente
      </button>
    </div>
  );
};

export default NotAuthorized;
