import React from "react";
import "./NotAuthorized.scss";

const SystemOffline = () => {
  return (
    <div className="notauthorized-container">
      <div className="dog-image"></div>

      <h3 className="notauthorized-title">
        Sistema temporariamente fora do ar
      </h3>
    </div>
  );
};

export default SystemOffline;
