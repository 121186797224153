export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export const formatDecimalToCurrency = (num) => {
  return toDecimal(num).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
};

export const toDecimal = (num) =>
  Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
