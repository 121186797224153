import { toast } from "react-toastify";
import { AbstractAudioRecorderMedia } from "./AbstractAudioRecorderMedia";

export class AudioRecorderOldGetUserMedia extends AbstractAudioRecorderMedia {
  name = "AudioRecorderOldGetUserMedia";

  async startRecording() {
    try {
      this.resetRecording();

      const self = this;
      console.log("start", self);

      if (
        !self.recorderHook.isRecording &&
        self.recorder?.state !== "recording"
      ) {
        // @ts-ignore
        navigator.getMedia(
          {
            audio: true,
          },
          function (stream) {
            self.localStream = stream;

            self.recorder = new MediaRecorder(stream);
            self.recorder.addEventListener(
              "dataavailable",
              self.onDataAvailable
            );
            console.log("start", self.onStop);
            console.log("start", self);

            self.recorder.addEventListener("stop", self.onStop);
            self.recorder.addEventListener("start", self.onStart);

            self.recorder.start();
          },
          function (error) {
            console.log(error);
            self.resetRecording();
          }
        );
      }
    } catch (error: any) {
      console.log(error);
      this.resetRecording();

      if (error.message === "getUserMedia is not implemented in this browser") {
        this.browserHandle.setNotRecordingCompatible();
        toast.error("Sem suporte para gravação de audio.");
      }

      if (error.message === "Permission denied") {
        toast.error("Sem permissão para gravar.");
      }
    }
  }
}
