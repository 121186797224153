import { CONTENTTYPE } from "./appReducer";

export const mapperMessages = (message) => {
  message.historic = true;

  if (typeof message.text !== "string")
    message.text = JSON.stringify(message.text);

  if (message.contentType === CONTENTTYPE.TEXT) {
    if (message.text.search(process.env.REACT_APP_BUCKET_STORAGE) > 0) {
      const msgText = message.text.toLowerCase();
      if (msgText.match(/\.(jpeg|jpg|png|gif|svg)/g)) {
        message.contentType = CONTENTTYPE.IMAGE;
      } else if (msgText.match(/\.(mp3|wav|ogg|flav)/g)) {
        message.contentType = CONTENTTYPE.AUDIO;
      } else if (msgText.match(/\.(pdf|doc|docx|log|xls|xlsx)/g)) {
        message.contentType = CONTENTTYPE.FILE;
      } else if (msgText.match(/\.(mp4|mov|avi|flv)/g)) {
        message.contentType = CONTENTTYPE.VIDEO;
      }
    } else if (
      message.text.match(
        /([+-]?([0-9]*[.])?[0-9]+)(\|)([+-]?([0-9]*[.])?[0-9]+)/g
      )
    ) {
      message.contentType = CONTENTTYPE.LOCATION;
    }
  } else if (message.contentType === CONTENTTYPE.IMAGE_LOCATION) {
    const msgText = JSON.parse(message.text);
    if (msgText.url) {
      message.text = msgText.url;
      message.contentType = CONTENTTYPE.IMAGE;
    } else {
      message.contentType = CONTENTTYPE.TEXT;
    }
  } else if (message.contentType === CONTENTTYPE.IMAGE_LOCATION) {
    const msgText = JSON.parse(message.text);
    if (msgText.url) {
      message.text = msgText.url;
      message.contentType = CONTENTTYPE.IMAGE;
    } else {
      message.contentType = CONTENTTYPE.TEXT;
    }
  }
};

export const sortByDate = (messages) => {
  const msgs = {};

  const days = Object.keys(messages);
  days.sort((a, b) => new Date(a) - new Date(b));

  days.forEach((d) => {
    msgs[d] = messages[d];
  });

  return msgs;
};
