import React, { useMemo } from "react";
import { AiFillCheckCircle, AiOutlineRight } from "react-icons/ai";
import { validLink } from "src/utils/formatCardText";
import { isDarkTheme } from "src/utils/changeTheme";
import { formatDecimalToCurrency } from "../../../utils/math";
import "./ListModalItem.scss";

export default function ListModalItem(props) {
  const {
    item,
    itemsSelected,
    handleSelectItem,
    hasDetails,
    handleOpenDetails,
  } = props;

  const hasSelected = !!itemsSelected.find(
    (itemSelected) => itemSelected.id === item.id
  );

  const hasImage = useMemo(() => {
    return validLink(item.image ?? "");
  }, [item.image]);

  return (
    <div
      className={
        "list-modal-item-container " + (isDarkTheme() ? "darkTheme" : "")
      }
    >
      <div
        className={`list-modal-item-container__content ${
          hasSelected ? "selected" : ""
        }`}
      >
        <div className="list-modal-item-container__left">
          {item.image &&
            (hasImage ? (
              <img src={item.image} alt={item.title} />
            ) : (
              <span>{item.image}</span>
            ))}
          {item.price && (
            <p>
              {formatDecimalToCurrency(
                typeof item.price === "string"
                  ? parseFloat(item.price)
                  : item.price
              )}
            </p>
          )}
        </div>

        <div
          className="list-modal-item-container__center"
          onClick={() => handleSelectItem(item)}
        >
          <strong>{item.title}</strong>
          <span>{item.description}</span>
        </div>

        {hasSelected && (
          <div className="list-modal-item-select-confirm">
            <AiFillCheckCircle size={25} />
          </div>
        )}

        {hasDetails && (
          <div
            className="list-modal-item-container__right"
            onClick={() => handleOpenDetails(item)}
          >
            <AiOutlineRight size={20} />
          </div>
        )}
      </div>
    </div>
  );
}
