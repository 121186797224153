import "./SendLocation.scss";
import React from "react";
import { StaticGoogleMap, Marker } from "react-static-google-map";
import { STATUS } from "../../../contexts/store/appReducer";

const SendLocation = ({ msg }) => {
  const { text } = msg;
  const longitude = Number(text.split("|")[0]);
  const latitude = Number(text.split("|")[1]);
  return (
    <div className="container-location">
      <StaticGoogleMap zoom={16} size="238x196" className="send-location" apiKey={process.env.REACT_APP_GOOGLEMAPS_KEY}>
        <Marker size="small" location={`${latitude},${longitude}`} />
      </StaticGoogleMap>
      <p className="message-info" style={{ marginRight: 8 }}>
        <span className="message-time">
          {new Date(msg.timestamp).getHours().toString().padStart(2, "0") +
            ":" +
            new Date(msg.timestamp).getMinutes().toString().padStart(2, "0")}
        </span>
        <span
          className={(() => {
            switch (msg.status) {
              case STATUS.SENT:
                return "message-status sent";
              case STATUS.RECEIVED:
                return "message-status read";
              case STATUS.ERROR:
              case STATUS.SENDING:
                return "message-status sending";
              default:
                return "";
            }
          })()}
        ></span>
      </p>
    </div>
  );
};

export default SendLocation;
