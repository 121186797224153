import React, { createContext } from "react";
import { toast } from "react-toastify";
import { NotificationModal } from "../../components/Payload/NotificationModal";
import { FirebaseNotificationPayload } from "./types";
import { useFirebaseNotification } from "./useFirebaseNotification";

export const FirebaseNotificationContext =
  createContext<FirebaseNotificationPayload | null>(null);

interface FirebaseProviderProps {
  children: JSX.Element;
}

export function FirebaseProvider({ children }: FirebaseProviderProps) {
  const handleOnMessage = (props) => {
    toast(<NotificationModal {...props.notification} />, {
      position: "top-right",
    });
  };

  const firebaseNotification = useFirebaseNotification({ handleOnMessage });

  return (
    <FirebaseNotificationContext.Provider value={firebaseNotification}>
      {children}
    </FirebaseNotificationContext.Provider>
  );
}
